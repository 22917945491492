import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import style from "./header.module.css"

const ListLink = props => <Link to={props.to}>{props.children}</Link>

const Header = ({ siteTitle, menuLinks }) => (
  <header id="site-header" className={style.masthead} role="banner">
    <div className={style.masthead_info}>
      <Link to="/">
        <img
          className={style.site_logo}
          src="/mor10logo.png"
          width="366"
          height="374"
          alt=""
        />
        <div className={style.site_title}>{siteTitle}</div>
        <div className={style.site_description}>
          thinking out loud about the internet
        </div>
      </Link>
    </div>

    <nav className={style.masthead_navigation}>
      <ul>
        {menuLinks.map(props => (
          <li key={props.name}>
            <ListLink to={props.link}>{props.name}</ListLink>
          </li>
        ))}
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
